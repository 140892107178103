// import { useCookie } from 'react-use';
import { USER_TOKEN_KEY } from 'utls';
import axios from 'axios';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useAuth } from 'context';

export const useInfiniteScroll = ({
  name_space,
  name,
  per_page,
  token
}: any) => {
  // const { token } = useAuth();

  console.log(token, 'tokentokentoken');

  const headers = {
    Authorization: `Bearer ${token}`
  };

  const fetchInfinite = async ({ pageParam = 1 }) => {
    const { data } = await axios.get(
      `https://${process.env.NEXT_PUBLIC_BACKEND_URL}/wp-json/${name_space}/${name}?page=${pageParam}&per_page=${per_page}`,
      {
        headers: headers
      }
    );
    const results = await data;

    // console.log(results, 'resultsresultsresults');

    return results;
  };

  const results = useInfiniteQuery([name_space], fetchInfinite, {
    getNextPageParam: (lastPage, pages) => {
      const nextPages = pages.length + 1;
      const items = lastPage?.data ? lastPage?.data?.length : lastPage?.length;
      return items === per_page ? nextPages : undefined;
    }
  });

  console.log(results, 'resultsresultsresults');

  const {
    data,
    hasNextPage,
    isFetchingNextPage,
    isLoading: isRQLoading,
    fetchNextPage,
    refetch
  } = results;

  const items = data?.pages?.flatMap(items => [...items.data]) ?? [];
  const isLoading = isRQLoading || isFetchingNextPage;

  console.log(items, 'itemsitemsitems');

  return {
    items,
    hasNextPage,
    isLoading,
    loadMore: () => fetchNextPage(),
    refetch
  };
};
