import { Box } from '@chakra-ui/react';
import { usePageContext } from 'context';
import Script from 'next/script';
import { Fragment } from 'react';

export const VideoAdsSlot: React.FC = () => {
  const { aniview } = usePageContext(v => v.siteConfig);
  const { aniview_settings: aniviewSettings } = aniview;

  const { id, url } = aniviewSettings ?? {};

  if (!id || !url) {
    return null;
  }

  return (
    <Fragment>
      <Box id='aniview' />
      <Script
        async
        strategy='afterInteractive'
        id={id}
        src={url}
        // id={'AV62aa11a765878f5a0c7ec0b7'}
        // src={
        //   'https://tg1.aniview.com/api/adserver/spt?AV_TAGID=62aa11a765878f5a0c7ec0b7&AV_PUBLISHERID=6124992a9c738f3419721fb3'
        // }
      />
    </Fragment>
  );
};
